import React, { useState, useEffect, useRef, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from 'react-router-dom';
import Loading from "../components/Global/Loading";
import moment from "moment-timezone";
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { toast } from 'react-toastify';
import { Auth } from 'aws-amplify';
import { Dialog } from 'primereact/dialog';
import { AuthContext } from '../components/AuthContext/AuthContext';
import axios from 'axios';
import SupportChat from "./SupportChat";
import SupportCreateTicket from "./SupportCreateTicket";
import "./page_tickets.css";
import "./page_dashboard.css";
import { onSupportActiveOpen, onSupportDeactiveOpen, onSupportDeactiveClose, onGetSupportActiveTicketsMore, onGetSupportDeactiveTicketsMore } from "../Reducer/kpriceReducer";

const SupportTickets = () => {

    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    const { isAuthenticated } = useContext(AuthContext);

    const dispatch = useDispatch();
    const location = useLocation();
    const { state } = location;
    const newLogIdFromCreateTicket = state?.logId || null;

    const ticketArea = useRef(null);
    const chatArea = useRef(null);

    const [tabValue, setTabValue] = useState("ACTIVE");
    const [tabData, setTabData] = useState(null);

    const supportActiveTickets = useSelector((state) => state.kprice.supportActiveTickets.data);
    const supportDeactiveTickets = useSelector((state) => state.kprice.supportDeactiveTickets.data);
    const [activeTicketsLoaded, setActiveTicketsLoaded] = useState(false);
    const [deactiveTicketsLoaded, setDeactiveTicketsLoaded] = useState(false);

    const [selectedTicketForChat, setSelectedTicketForChat] = useState(null);
    const [selectedTicketForChatState, setSelectedTicketForChatState] = useState(null);
    const [selectedTicketForChatStatus, setSelectedTicketForChatStatus] = useState(null);

    const [closeTicketLoadingList, setCloseTicketLoadingList] = useState([]);

    const [ticketLogIdForCreateTicket, setTicketLogIdForCreateTicket] = useState(null);
    const [displayCreateTicketDialog, setDisplayCreateTicketDialog] = useState(false);

    const [loadingMore, setLoadingMore] = useState(false);
    const itemsScrollPosition = useRef(0);

    useEffect(() => {
        if(newLogIdFromCreateTicket){
            setTicketLogIdForCreateTicket(newLogIdFromCreateTicket);

            if (state?.logId) {
                const newState = { ...location.state, logId: null };
                window.history.replaceState(newState, document.title, window.location.pathname);
            }
        }
    }, [newLogIdFromCreateTicket]);

    useEffect(() => {
        if(ticketLogIdForCreateTicket){
            const newLogId = ticketLogIdForCreateTicket;
            setDisplayCreateTicketDialog(false);

            if(supportActiveTickets && supportActiveTickets.support_requests){
                let newChat = null;

                const existingIndex = supportActiveTickets.support_requests.findIndex(
                    item => item.log_id === newLogId
                );

                if (existingIndex != -1) {
                    newChat = supportActiveTickets.support_requests[existingIndex];
                }

                if(newChat){
                    setTicketLogIdForCreateTicket(null);
                    setSelectedTicketForChat(newChat);
                    setSelectedTicketForChatState(newChat.state);
                    setSelectedTicketForChatStatus(newChat.status);
                }
            }
        }
    }, [ticketLogIdForCreateTicket, supportActiveTickets]);

    const closeTicket = async (logId, state, status) => {
        if(logId && state != "closed" && status != "deactive"){
            setCloseTicketLoadingList((prevItems) => [...prevItems, logId]);

            let data = {
                "ticket_log_id": logId,
                "state": "closed",
            }
            
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/support/updatestate', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    
                }
                else{//hatalı durum için
                    toast.error(`There was a problem closing the ticket!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
            })
            .finally(function () {
                setCloseTicketLoadingList((prevItems) => prevItems.filter((item) => item !== logId));
            });
        }
    }

    useEffect(() => {
        if(ticketArea && ticketArea.current){
            if(loadingMore){
                ticketArea.current.scrollTop = ticketArea.current.scrollHeight - ticketArea.current.clientHeight;
            }
            else{
                ticketArea.current.scrollTop = itemsScrollPosition.current;
            }
        }
    }, [loadingMore]);

    const elementsOnScroll = async (e) => {
        const { scrollTop, scrollHeight, clientHeight } = e.target;

        let ticketsData = tabValue == "ACTIVE" ? supportActiveTickets : supportDeactiveTickets;

        if (isAuthenticated && (scrollTop + clientHeight + 5 >= scrollHeight) && ticketsData && ticketsData.last_evaluated_key && !loadingMore) {//eğer divin en altına gelindiyse
            if(ticketArea && ticketArea.current){
                itemsScrollPosition.current = ticketArea.current.scrollTop;
            }

            setLoadingMore(true);

            if(tabValue == "ACTIVE"){
                dispatch(onGetSupportActiveTicketsMore());
            }
            else{
                dispatch(onGetSupportDeactiveTicketsMore());
            }
        }
    }
    
    const ticketStateText = (state) => {
        if(state == "open"){return "Open";}
        else if(state == "closed"){return "Closed";}
        else if(state == "reopened"){return "Reopened";}
        else if(state == "waiting_for_customer"){return "Waiting for Customer";}
        else if(state == "in_progress"){return "In Progress";}
        else if(state == "resolved"){return "Resolved";}
        else{return null;}
    }

    const ticketStateColor = (state) => {
        if(state == "open"){return "success1";}
        else if(state == "reopened"){return "success2";}
        else if(state == "in_progress"){return "waiting2";}
        else if(state == "waiting_for_customer"){return "waiting1";}
        else if(state == "closed"){return "danger1";}
        else if(state == "resolved"){return "danger2";}
        else{return "";}
    }

    const ticketDateText = (timestamp) => {
        let eventDateText = timestamp;
        
        const now = moment().tz(timeZone);
        let eventDate = moment(eventDateText).tz(timeZone);

        if(eventDate.isSame(now, 'day')){
            eventDateText = eventDate.format("HH:mm");
        }
        else if(eventDate.isSame(now.clone().subtract(1, 'day'), 'day')){
            eventDateText = "Yesterday";
        }
        else if(eventDate.isAfter(now.clone().subtract(7, 'days'), 'day')){
            eventDateText = eventDate.format("dddd");
        }
        else{
            eventDateText = eventDate.format("DD.MM.YYYY");
        }

        return eventDateText;
    }

    useEffect(() => {
        if(tabValue == "ACTIVE"){
            setTabData(supportActiveTickets && supportActiveTickets.support_requests ? supportActiveTickets.support_requests : null);
        }
        else if(tabValue == "DEACTIVE"){
            setTabData(supportDeactiveTickets && supportDeactiveTickets.support_requests ? supportDeactiveTickets.support_requests : null);
        }
    }, [tabValue, supportActiveTickets, supportDeactiveTickets]);

    useEffect(() => {
        setLoadingMore(false);
        
        if(selectedTicketForChat && selectedTicketForChat.log_id){
            let newChat = null;

            if(supportActiveTickets && supportActiveTickets.support_requests){
                const existingIndex = supportActiveTickets.support_requests.findIndex(
                    item => item.log_id === selectedTicketForChat.log_id
                );

                if (existingIndex != -1) {
                    newChat = supportActiveTickets.support_requests[existingIndex];
                }
            }

            if(supportDeactiveTickets && supportDeactiveTickets.support_requests){
                const existingIndex = supportDeactiveTickets.support_requests.findIndex(
                    item => item.log_id === selectedTicketForChat.log_id
                );

                if (existingIndex != -1) {
                    newChat = supportDeactiveTickets.support_requests[existingIndex];
                }
            }

            if(newChat){
                setSelectedTicketForChatState(newChat.state);
                setSelectedTicketForChatStatus(newChat.status);
            }
        }
    }, [supportActiveTickets, supportDeactiveTickets]);

    useEffect(() => {
        if(isAuthenticated){
            if(!activeTicketsLoaded){
                dispatch(onSupportActiveOpen());
                setActiveTicketsLoaded(true);
            }

            if(!deactiveTicketsLoaded){
                dispatch(onSupportDeactiveOpen());
                setDeactiveTicketsLoaded(true);
            }

            ticketArea.current.style.animation = "none";
            let tempForAnimation1 = ticketArea.current.offsetHeight;
            ticketArea.current.style.animation = "open-animation 1.5s";
        }
    }, [tabValue, isAuthenticated]);
    
    useEffect(() => {
        if(selectedTicketForChat){
            setSelectedTicketForChatState(selectedTicketForChat.state);
            setSelectedTicketForChatStatus(selectedTicketForChat.status);
            
            chatArea.current.style.animation = "none";
            let tempForAnimation2 = chatArea.current.offsetHeight;
            chatArea.current.style.animation = "open-animation 1.5s";
        }
    }, [selectedTicketForChat]);

    useEffect(() => {
        return () => {
            dispatch(onSupportDeactiveClose());
        };
    }, []);
    
    return(
        <div id="main" className="main">
            {isAuthenticated ? 
                <>
                    <Dialog header="Create Ticket" visible={displayCreateTicketDialog} draggable={false} resizable={false} className="section-walletlist-dialog transfer" onHide={() => setDisplayCreateTicketDialog(false)}>
                        <SupportCreateTicket pageType="dialog" setLogId={setTicketLogIdForCreateTicket} />
                    </Dialog>
                    <div className="section-tickets">
                        <div className="tickets-area">
                            <div className="allsections allsections-border tickets-inner-area">
                                <div className="tab-area">
                                    <label className={tabValue == "ACTIVE" ? "active" : undefined} onClick={() => {setTabValue("ACTIVE");}}>ACTIVE</label>
                                    <label className={tabValue == "DEACTIVE" ? "active" : undefined} onClick={() => {setTabValue("DEACTIVE");}}>DEACTIVE</label>
                                </div>
                                <div className="tickets" ref={ticketArea} onScroll={(e) => elementsOnScroll(e)}>
                                    {tabData == null ? 
                                        <div className="loading">
                                            <Loading />
                                        </div>
                                        : 
                                        <>
                                            {tabData.length > 0 ? 
                                                <>
                                                    {tabData.map((item) => (
                                                        ((tabValue == "ACTIVE" && item.status == "active") || (tabValue == "DEACTIVE" && item.status == "deactive")) && 
                                                            <div key={item.log_id} className={`item ${selectedTicketForChat && selectedTicketForChat.log_id == item.log_id && "active"}`} onClick={() => setSelectedTicketForChat(item)}>
                                                                <div className="signal">
                                                                    {item.last_event_user_type == "Support" && item.status == "active" && <i className="fa-solid fa-exclamation"></i>}
                                                                </div>
                                                                <div className="status">
                                                                    <div className={`status-info ${ticketStateColor(item.state)}`}>{ticketStateText(item.state)}</div>
                                                                    <div className="ticket-info"><div className="ticket-info1">Ticket ID:</div><div className="ticket-info2">{item.ticket_id}</div></div>
                                                                </div>
                                                                <div className="content">
                                                                    <div className="subject-area">
                                                                        <div className="subject">{item.subject}</div>
                                                                        <div className="date">{ticketDateText(item.last_event_timestamp)}</div>
                                                                    </div>
                                                                    <div className={`message-area ${item.state == "closed" && item.status == "deactive" ? "with-close-button" : ""}`} >
                                                                        {item.last_event_type && item.last_event_type == "Message" ? 
                                                                            <>
                                                                                <div className="message-user">{item.last_event_user_type}:</div>
                                                                                <div className="message">{item.last_event_content}</div>
                                                                            </>
                                                                            : 
                                                                            (item.last_event_type && item.last_event_type == "Photo" ? 
                                                                                <>
                                                                                    <div className="message-user">{item.last_event_user_type}:</div>
                                                                                    <i className="fa-solid fa-camera"></i>
                                                                                    <div className="message">Photo</div>
                                                                                </>
                                                                                : 
                                                                                (item.last_event_type && item.last_event_type == "File" ? 
                                                                                    <>
                                                                                        <div className="message-user">{item.last_event_user_type}:</div>
                                                                                        <i className="fa-solid fa-file-lines"></i>
                                                                                        <div className="message">File</div>
                                                                                    </>
                                                                                    : 
                                                                                    null
                                                                                )
                                                                            )
                                                                        }
                                                                        {item.state == "resolved" && item.status != "deactive" && item.log_id && 
                                                                            (closeTicketLoadingList.includes(item.log_id) ? 
                                                                                <div className="close-button-loading"><Loading /></div>
                                                                                : 
                                                                                <Tooltip title="Close Ticket" className="close-button" onClick={(event) => {event.stopPropagation();closeTicket(item.log_id, item.state, item.status);}}>
                                                                                    <IconButton>
                                                                                        <DeleteIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    ))}
                                                    {loadingMore && <div className="loading-more"><Loading /></div>}
                                                </>
                                                : 
                                                <div className="no-tickets">
                                                    <i className="fa-solid fa-comment-slash"></i>
                                                    <span>No ticket</span>
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="create-ticket" onClick={() => setDisplayCreateTicketDialog(true)}><i className="fa-solid fa-circle-plus"></i> Create Ticket</div>
                        </div>
                        <div className="allsections allsections-border chat-area">
                            <div className="chat" ref={chatArea} key={selectedTicketForChat && selectedTicketForChat.log_id ? selectedTicketForChat.log_id : undefined}>
                                {selectedTicketForChat ? 
                                    <SupportChat ticket={selectedTicketForChat} ticketStateText={ticketStateText} ticketStateColor={ticketStateColor} ticketState={selectedTicketForChatState} ticketStatus={selectedTicketForChatStatus} />
                                    : 
                                    <div className="no-chat">
                                        <i className="fa-solid fa-comment-dots"></i>
                                        <span>Select a ticket to take action</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
                : 
                <div className="create-ticket-section allsections allsections-border">
                    <div className="main-form">
                        <div className="title">
                            <p>You must log in to create a support request</p>
                        </div>
                        <Link to="/login">Login</Link>
                        <span>If you cannot access your account, you can create a support request at <a href="mailto:support@kpricemarket.com" className="email" style={{color: "inherit"}}><b>support@kpricemarket.com</b></a></span>
                    </div>
                </div>
            }
        </div>
    )

}

export default SupportTickets;