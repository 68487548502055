import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../components/Global/Loading";
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { toast } from 'react-toastify';
import Tooltip from '@mui/material/Tooltip';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { onSupportChatOpen, onSupportChatClose, onGetSupportChatMore } from "../Reducer/kpriceReducer";

const SupportChat = ({ ticket, ticketStateText, ticketStateColor, ticketState, ticketStatus }) => {

    const dispatch = useDispatch();

    const supportChat = useSelector((state) => state.kprice.supportChat.data);
    const messagesRef = useRef();
    
    const [newMessage, setNewMessage] = useState("");
    const [newMessageDanger, setNewMessageDanger] = useState(false);
    const [newMessageLoading, setNewMessageLoading] = useState(false);
    const [newMessageAlert, setNewMessageAlert] = useState(true);
    const newMessageRef = useRef("");
    const inputRef = useRef(null);
    const [firstLoad, setFirstLoad] = useState(0);

    const [loadingMore, setLoadingMore] = useState(false);
    const itemsScrollPosition = useRef(0);

    const chatScrollTop = useRef(0);
    const chatScrollHeight = useRef(0);
    const chatClientHeight = useRef(0);

    const [closeTicketLoading, setCloseTicketLoading] = useState(false);

    const patterns = {
        general: /^[^'`"“”‘’«»‹›\<\>;\{\}\$\&\\\%\*\@\!\?\#\^\|\[\]\+=~\/\(\)]*$/,
        no_double_space_or_trim: /^(?! )(?!.*  )(?!.* $).+$/,
        no_other_spaces: /^[^\t\n\r]*$/,
        char_limit_100: /^.{1,100}$/,
        char_limit_1000: /^.{1,1000}$/,
    };

    const validatePattern = (field, fieldLabel) => {
        if (!patterns["general"].test(field)) {
            return `Invalid character in ${fieldLabel}`;
        }
        else if (!patterns["no_double_space_or_trim"].test(field)) {
            return `Invalid spacing in ${fieldLabel}`;
        }
        else if (!patterns["no_other_spaces"].test(field)) {
            return `Unexpected spaces in ${fieldLabel}`;
        }
        else if (!patterns["char_limit_1000"].test(field) && fieldLabel == "Message") {
            return `${fieldLabel} exceeds character limit`;
        }
        else if (!patterns["char_limit_100"].test(field) && fieldLabel == "Subject") {
            return `${fieldLabel} exceeds character limit`;
        }
    };

    useEffect(() => {
        if(ticket && ticket.log_id){
            dispatch(onSupportChatOpen({ logId: ticket.log_id }));
        }
    }, [ticket]);
    
    const addMessage = async (isSubmit) => {
        setNewMessageDanger(false);
        const newValue = newMessageRef.current;

        if(newValue != ""){
            if(isSubmit && ticket && ticket.log_id && !newMessageLoading){
                let submitControl = true;

                let tempReturnValue = validatePattern(newValue, "Message");
                if(tempReturnValue){
                    submitControl = false;
                    setNewMessageDanger(true);
                    toast.error(tempReturnValue);
                }

                if(submitControl){
                    setNewMessageLoading(true);

                    let data = {
                        "ticket_log_id": ticket.log_id,
                        "message": newValue,
                    }
                    
                    const session = await Auth.currentSession();
                    const jwtToken = session.getIdToken().getJwtToken();
        
                    axios.post('https://api.kpricemarket.com/support/update', 
                        JSON.stringify(data),
                        { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                    )
                    .then(function (response) {
                        if(response.status == "200"){
                            newMessageRef.current = "";
                            setNewMessage("");
                        }
                        else{//hatalı durum için
                            toast.error(`There was a problem sending the message, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                            setNewMessageLoading(false);
                        }
                    })
                    .catch(function (error) {
                        toast.error(error.response.data.error);
                        setNewMessageLoading(false);
                    });
                }
            }
            else if(validatePattern(newValue, "Message")){
                setNewMessageDanger(true);
            }
        }
    }

    const closeTicketControl = () => {
        if(ticketState != "closed" && ticketStatus != "deactive" && ticket && ticket.log_id){
            confirmDialog({
                message: 'Are you sure you want to close the ticket?',
                header: 'Close Ticket',
                icon: 'pi pi-exclamation-triangle',
                accept: () => closeTicket(),
                draggable: false,
            });
        }
    }

    const closeTicket = async () => {
        if(ticketState != "closed" && ticketStatus != "deactive" && ticket && ticket.log_id){
            setCloseTicketLoading(true);

            let data = {
                "ticket_log_id": ticket.log_id,
                "state": "closed",
            }
            
            const session = await Auth.currentSession();
            const jwtToken = session.getIdToken().getJwtToken();

            axios.post('https://api.kpricemarket.com/support/updatestate', 
                JSON.stringify(data),
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
            )
            .then(function (response) {
                if(response.status == "200"){
                    
                }
                else{//hatalı durum için
                    toast.error(`There was a problem closing the ticket!${response.data.message ? ` (${response.data.message})` : ""}`);
                }
            })
            .catch(function (error) {
                toast.error(error.response.data.error);
            })
            .finally(function () {
                setCloseTicketLoading(false);
            });
        }
    }

    useEffect(() => {
        addMessage(false);
    }, [newMessage]);

    useEffect(() => {
        if(messagesRef && messagesRef.current){
            if(!loadingMore){
                messagesRef.current.scrollTop = messagesRef.current.scrollHeight - itemsScrollPosition.current - 66;
            }
        }
    }, [loadingMore]);

    const elementsOnScroll = async (e) => {
        const { scrollTop } = e.target;

        if (scrollTop == 0 && supportChat && supportChat.last_evaluated_key && !loadingMore) {//eğer divin en üstüne gelindiyse
            if(messagesRef && messagesRef.current){
                itemsScrollPosition.current = messagesRef.current.scrollHeight - messagesRef.current.scrollTop;
            }

            setLoadingMore(true);
            dispatch(onGetSupportChatMore());
        }
        
        chatScrollTop.current = e.target.scrollTop;
        chatScrollHeight.current = e.target.scrollHeight;
        chatClientHeight.current = e.target.clientHeight;

        const messageDistance = window.innerWidth > 1600 ? 35 : (window.innerWidth > 500 ? 25 : 15);

        if(chatScrollTop.current + chatClientHeight.current >= chatScrollHeight.current - messageDistance){
            setNewMessageAlert(false);
        }
    }

    const scrollDown = () => {
        if(!loadingMore && messagesRef.current){
            messagesRef.current.scrollTo({
                top: messagesRef.current.scrollHeight,
                behavior: 'smooth',
            });
        }

        setNewMessageAlert(false);
    }

    useEffect(() => {
        if(firstLoad != 2 && messagesRef.current){
            messagesRef.current.scrollTo({
                top: messagesRef.current.scrollHeight,
                //behavior: 'smooth',
            });

            if(firstLoad == 0){setFirstLoad(1);}
            else{setFirstLoad(2);}
        }
        else{
            if(!loadingMore && messagesRef.current){
                const messageDistance = window.innerWidth > 1600 ? 35 : (window.innerWidth > 500 ? 25 : 15);
                if(!(chatScrollTop.current + chatClientHeight.current >= chatScrollHeight.current - messageDistance)){
                    setNewMessageAlert(true);
                }
                else{
                    scrollDown();
                }
            }
        }
        
        setLoadingMore(false);

        if(newMessageLoading){
            setNewMessageLoading(false);
            inputRef.current.focus();
        }
    }, [supportChat]);

    useEffect(() => {
        return () => {
            dispatch(onSupportChatClose());
        };
    }, []);

    return(
        <div className="inner-chat">
            <ConfirmDialog />
            <div className={`messages ${supportChat && supportChat.support_request_events && supportChat.support_request_events.length > 0 ? "" : "loading"}`}>
                <div className="title-area">
                    <div className="ticket-info">
                        <div className="ticket-id">Ticket ID: <b>{ticket.ticket_id}</b></div>
                        <div className={`ticket-state ${ticketStateColor(ticketState)}`}>{ticketStateText(ticketState)}</div>
                    </div>
                    <Tooltip title={ticket.subject}>
                        <div className="subject-info">
                            <div className="subject">{ticket.subject}</div>
                        </div>
                    </Tooltip>
                    {ticketState != "closed" && ticketStatus != "deactive" && ticket && ticket.log_id && 
                        <div className={`state-button ${closeTicketLoading ? "loading" : ""}`} onClick={() => closeTicketControl()}>{closeTicketLoading ? <Loading /> : "Close Ticket"}</div>
                    }
                </div>
                <div className="inner" ref={messagesRef} onScroll={(e) => elementsOnScroll(e)}>
                    {supportChat && supportChat.support_request_events && supportChat.support_request_events.length > 0 ? 
                        <>
                            {loadingMore && <div className="loading-more"><Loading /></div>}
                            {supportChat.support_request_events.map((item, itemIndex) => (
                                <div className="item" key={item.event_id}>
                                    <div className={item.event_user_type == "Customer" ? "right-field" : (item.event_user_type == "Support" ? "left-field" : null)}>
                                        <div className={`message ${item.options && item.options.length > 0 ? "with-option" : ""}`}>
                                            {item.event_message}
                                        </div>
                                        {item.options && item.options.length > 0 && 
                                            item.options.map((option, optionIndex) => (
                                                <div className="option" key={`${itemIndex}-${optionIndex}`}>{option.title}</div>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))}
                        </>
                        : (<div className="loading"><Loading /></div>)
                    }
                </div>
            </div>
            <div className="input-area">
                {newMessageAlert && <div className="new-message-alert" onClick={() => scrollDown()}><i className="fa-solid fa-chevron-down"></i></div>}
                <div className="input-inner-area">
                    <input 
                        ref={inputRef}
                        type="text"
                        onChange={(event) => {if(!newMessageLoading){newMessageRef.current=event.target.value;setNewMessage(event.target.value);}}}
                        value={newMessage}
                        placeholder="Type a message"
                        className={`${newMessage != "" ? "with-button " : undefined} ${newMessageDanger ? "danger " : undefined}`}
                        onKeyDown={(event) => {if(event.key === "Enter"){addMessage(true);}}}
                    />
                    {newMessage != "" && supportChat && supportChat.support_request_events && supportChat.support_request_events.length > 0 && 
                        <i className="fa-solid fa-circle-right send-button" onClick={() => addMessage(true)}></i>
                    }
                    {newMessageLoading && <div className="new-message-loading"><Loading /></div>}
                </div>
            </div>
        </div>
    )

}

export default SupportChat;