import React, { useState } from "react";
import "./page_tickets.css";
import { Link, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import { toast } from 'react-toastify';
import Loading from "../components/Global/Loading";

const SupportCreateTicket = ({ pageType, setLogId }) => {

    const navigate = useNavigate();

    const [subject, setSubject] = useState("");
    const [subjectDanger, setSubjectDanger] = useState(false);
    const [message, setMessage] = useState("");
    const [messageDanger, setMessageDanger] = useState(false);
    const [submitFormLoading, setSubmitFormLoading] = useState(false);

    const patterns = {
        general: /^[^'`"“”‘’«»‹›\<\>;\{\}\$\&\\\%\*\@\!\?\#\^\|\[\]\+=~\/\(\)]*$/,
        no_double_space_or_trim: /^(?! )(?!.*  )(?!.* $).+$/,
        no_other_spaces: /^[^\t\n\r]*$/,
        char_limit_100: /^.{1,100}$/,
        char_limit_1000: /^.{1,1000}$/,
    };

    const validatePattern = (field, fieldLabel) => {
        if (field == "") {
            return `Please enter your ${fieldLabel}`;
        }
        else if (!patterns["general"].test(field)) {
            return `Invalid character in ${fieldLabel}`;
        }
        else if (!patterns["no_double_space_or_trim"].test(field)) {
            return `Invalid spacing in ${fieldLabel}`;
        }
        else if (!patterns["no_other_spaces"].test(field)) {
            return `Unexpected spaces in ${fieldLabel}`;
        }
        else if (!patterns["char_limit_1000"].test(field) && fieldLabel == "Message") {
            return `${fieldLabel} exceeds character limit`;
        }
        else if (!patterns["char_limit_100"].test(field) && fieldLabel == "Subject") {
            return `${fieldLabel} exceeds character limit`;
        }
    };

    const clearAllDanger = () => {
        setSubjectDanger(false);
        setMessageDanger(false);
    }

    const submitForm = async (value, area) => {
        clearAllDanger();
        let tempReturnValue = null;

        if(area == "Submit"){
            let submitControl = true;

            tempReturnValue = validatePattern(subject, "Subject");
            if(tempReturnValue){
                submitControl = false;
                setSubjectDanger(true);
                toast.error(tempReturnValue);
            }

            tempReturnValue = validatePattern(message, "Message");
            if(tempReturnValue){
                submitControl = false;
                setMessageDanger(true);
                toast.error(tempReturnValue);
            }

            if(submitControl){
                setSubmitFormLoading(true);

                let data = {
                    "subject": subject,
                    "message": message,
                }

                const session = await Auth.currentSession();
                const jwtToken = session.getIdToken().getJwtToken();
    
                axios.post('https://api.kpricemarket.com/support', 
                    JSON.stringify(data),
                    { headers: { 'Content-Type': 'application/json', 'Authorization': `Bearer ${jwtToken}` } }
                )
                .then(function (response) {
                    if(response.status == "200"){
                        toast.success("Your support request has been submitted successfully!");
                        setSubject("");
                        setMessage("");
                        setTimeout(() => {
                            clearAllDanger();
                        }, 100);

                        let ticketLogId = null;
                        if(response && response.data && response.data.log_id){
                            ticketLogId = response.data.log_id;
                        }
                        
                        if(ticketLogId){
                            if(pageType == "dialog"){
                                setLogId(ticketLogId);
                            }
                            else if(pageType == "profile"){
                                navigate('/support', {
                                    state: { logId: ticketLogId },
                                });
                            }
                        }
                    }
                    else{//hatalı durum için
                        toast.error(`There was a problem sending the support request, please try again!${response.data.message ? ` (${response.data.message})` : ""}`);
                    }
                })
                .catch(function (error) {
                    toast.error(error.response.data.error);
                })
                .finally(function () {
                    setSubmitFormLoading(false);
                });
            }
        }
        else{
            if(area == "Subject"){
                tempReturnValue = validatePattern(value, area);
                if(tempReturnValue){
                    setSubjectDanger(true);
                }

                tempReturnValue = validatePattern(message, "Message");
                if(tempReturnValue){
                    setMessageDanger(true);
                }
            }
            if(area == "Message"){
                tempReturnValue = validatePattern(value, area);
                if(tempReturnValue){
                    setMessageDanger(true);
                }

                tempReturnValue = validatePattern(subject, "Subject");
                if(tempReturnValue){
                    setSubjectDanger(true);
                }
            }
        }
    }

    return(
        <div className="create-ticket-section">
            <div className={`main-form ${pageType == "dialog" ? "dialog-content" : ""}`}>
                <div className="inputs new-password">
                    <label>Subject</label>
                    <input type="text" placeholder="Subject" className={subjectDanger ? "danger" : undefined} value={subject} onChange={(event) => {setSubject(event.target.value);submitForm(event.target.value, "Subject");}} />
                    <label>Message</label>
                    <textarea placeholder="Message" className={messageDanger ? "danger" : undefined} value={message} onChange={(event) => {setMessage(event.target.value);submitForm(event.target.value, "Message");}}></textarea>
                </div>
                <button type="button" onClick={() => submitForm(null, "Submit")} disabled={submitFormLoading}>{submitFormLoading ? <Loading /> : "Submit"}</button>
                {pageType == "profile" && 
                    <Link to="/support" className="bottom-buton">
                        <i class="fa-solid fa-clock-rotate-left"></i>
                        Go to Support Tickets
                    </Link>
                }
            </div>
        </div>
    )

}

export default SupportCreateTicket;